<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div
      class="section1 small"
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
          class="mt-16"
        >
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ml-16 mt-16">
          <div
            class="travel-text mt-0"
          >
            Who needs car <br> insurance?
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="section2">
      <v-row>
        <v-col class="mt-16">
          <v-spacer class="mt-16"></v-spacer>
        </v-col>
      </v-row>
    </div>
    <div class="section3 pb-12">
      <v-row>
        <v-col cols="6">
          <div class="insurance mt-10 ml-16">
            Car Insurance
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col cols="9">
          <div class="sec3-sub mt-10 ml-16 pb-16">
            If you own/lease a car then you probably need auto insurance. Besides your home, your car may very well be your most valuable asset.
          </div>
        </v-col>
      </v-row>
      <!-- <v-img
        src="/img/home-hand 1.png"
        :class="{'home-icon-small': $vuetify.breakpoint.lgAndDown, 'home-icon-large': $vuetify.breakpoint.xlOnly}"
      ></v-img>
      <v-img
        src="/img/home-underline.png"
        :class="{'home-underline-small': $vuetify.breakpoint.lgAndDown, 'home-underline-large': $vuetify.breakpoint.xlOnly}"
      ></v-img> -->
    </div>
    <div class="section4 mt-n16 pb-16">
      <v-row class="pb-12">
        <v-col
          cols="7"
          class="mt-16 pr-n8"
        >
          <v-img
            src="/img/model-car.png"
            class="travel-fam"
          ></v-img>
        </v-col>
        <v-col
          cols="5"
          class=" mt-4 ml-n8"
          style="z-index: 5;"
        >
          <div
            class="free-look pt-4"
            :class="{'mt-0': $vuetify.breakpoint.lgAndDown, 'mt-16 pt-16': $vuetify.breakpoint.xlOnly}"
          >
            Some important things to consider when choosing your policy include: <br> <br>
          </div>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Look for coverage limits that protect your auto and personal assets if you're at fault in an accident.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Select a policy that suits your individual needs with coverage options like collision and comprehensive, uninsured/underinsured motorist, towing and rental.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                If you bundle your auto and home with us you can enjoy a bundling discount.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Look for a policy that rewards you for a good driving record, buying a car with safety and theft-prevention technologies, and having a good student on the policy.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Has good customer service if and when there is a claim.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Has a solid reputation and high ratings by independent evaluators.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="section5 mb-n16 ">
        <v-row
          justify="center"
          class="pt-16"
        >
          <v-col
            cols="8"
            class="d-flex justify-center pt-16"
          >
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
.section1{
  position: relative;
  width: 100%;
  background: linear-gradient(to left, rgba(255,255,255,0) 65%, rgba(255,255,255,1) 100%),url( '/img/autolearnmore-background.png') no-repeat center center;
  background-size: cover;
}

.section2 {
    background-color: #FFFFFF;
}

.section3 {
    background-color: #DEECF1;
}

.section4 {
    background-color: #FFFFFF;
}

.covid-coverage-small {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 0;
    top: 75rem;
}

.covid-coverage-large {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 0;
    top: 90rem;
}

.free-look-small {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 16rem;
    top: 75rem;
}

.free-look-large {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 16rem;
    top: 90rem;
}

.sec3-sub {
font-size: 28px;
line-height: 132.19%;
color: #757575;
}

.travel-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
letter-spacing: -0.005em;

color: #606060;
}

.insurance {
font-size: 60px;
line-height: 70px;
letter-spacing: -0.005em;
color: #757575;
}

.ins-card {
    background-color: #EEEEEE;
    width: 425px;
    height: 252px;
}

.learn {
  border: 1px solid #00A1B7 !important;
}

.sec4-txt {
    font-size: 55px;
line-height: 64px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
}

.plan-img {
    max-width: 155px;
    height: auto;
    object-fit: scale-down;
}

.coverage-img {
    max-width: 130px;
    height: auto;
    object-fit: scale-down;
}

.payout-img {
    max-width: 145px;
    height: auto;
    object-fit: scale-down;
}

.customercare-img {
    max-width: 136px;
    height: 119px;
    object-fit: scale-down;
}
.sec4-subtxt {
font-weight: 500;
font-size: 24px;
line-height: 32px;
text-align: center;
text-transform: capitalize;
color: #212121;
}

.sec4-info {
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    font-feature-settings: 'liga' off;
    color: #757575;
}

.section5 {
    height: 500px;
    width: 100%;
    background: rgba(216, 253, 215, 0.5);
}

.section5-txt{
font-size: 26px;
line-height: 42px;
text-align: center;
color: #757575;
}

.small {
    height: 700px;
}

.large {
    height: 900px;
}

.travel-fam {
    margin-top: 10rem;
    max-width: 768px;
    height: 512px;
    object-fit: scale-down;
}

.free-look {
    font-weight: 500;
font-size: 36px;
line-height: 42px;
color: #757575;
}

.free-look-txt {
font-size: 24px;
line-height: 33px;
font-feature-settings: 'liga' off;
color: #757575;
}

.btm-txt {
font-size: 20px;
line-height: 26px;
text-align: center;
color: #757575;
}

.home-icon-small {
    position: absolute;
    max-width: 175px;
    height: auto;
    object-fit: scale-down;
    right: 5rem;
    top: 76.75rem;
}
.home-icon-large {
    position: absolute;
    max-width: 175px;
    height: auto;
    object-fit: scale-down;
    right: 5rem;
    top: 87rem;
}
.home-underline-small {
    position: absolute;
    height: 4px;
    object-fit: scale-down;
    right: 0;
    top: 89rem;
}
.home-underline-large {
    position: absolute;
    height: 4px;
    object-fit: scale-down;
    right: 0;
    top: 99.2rem;
}

.main-cvg-txt {
    font-size: 55px;
    line-height: 64px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #757575;
}

.extras-title {
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
}

.extras-text {
    font-weight: 700;
    font-size: 26px;
    line-height: 157.69%;
    color: #212121;
    background-color: #EDF8FA;
    height: 64px;
    width: 100%;
}
</style>
